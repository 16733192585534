/**
 *
 *
 *
 *
 *
 *
 *
 */
import { o_util } from "@gr-common/head/namespaces";

export const { toggle } = o_util;
export const { get } = toggle ?? {};
