/*                                     */
/**
 *
 *
 *
 *
 *
 *
 */

import { o_global } from "@gr-common/head/namespaces";
import { type OttoNexus, otto } from "./nexus.js";

export type * from "../lib/debug/index.js";

/*                                             */
export const debugSync = o_global.debug ?? {};
export const logger = debugSync.logger;
export const scope = debugSync.scope;
export const Level = debugSync.Level;
export const status = debugSync.status;
/*                                                                */
export const sendLog: OttoNexus["apm"]["sendCustomError"] = (otto.apm ?? {}).sendCustomError;
