import { sendCustomError } from "../../exports/apm.js";
import { cookie } from "../../exports/cookie.js";

/**
 *
 *
 */
function checkStorage(storage?: Storage): Storage | undefined {
  if (!storage) {
    return undefined;
  }

  try {
    storage.setItem("storageTest", "test");
    storage.getItem("storageTest");
    storage.removeItem("storageTest");
    return storage;
  } catch {
    return undefined;
  }
}

/**
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 */
export type StorageWrapperFunc = () => Storage;

/*                                               */
type StorageEx = Omit<Storage, "length"> & Pick<Partial<Storage>, "length">;

/**
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 */
export class GlobalStorage implements StorageEx {
  /**
 *
 *
 */
  private storage?: Storage;

  /**
 *
 */
  public get isAvailable(): boolean {
    return !!this.storage;
  }

  /**
 *
 *
 */
  public get length(): number | undefined {
    /*                                                  */
    return this.storage ? Object.keys(this.storage).length : undefined;
  }

  /**
 *
 *
 *
 *
 *
 */
  public constructor(storageObjectOrFunction: Storage | StorageWrapperFunc) {
    /*                                           */
    if (cookie.exists("app") && navigator.userAgent.indexOf("OS 11_3 like Mac OS X") !== -1) {
      return;
    }

    /*                                                       */
    /*                                                      */
    /*                                   */
    if (typeof storageObjectOrFunction === "function") {
      try {
        this.storage = storageObjectOrFunction();
      } catch {
        /*            */
      }
    } else {
      this.storage = storageObjectOrFunction;
    }

    this.storage = checkStorage(this.storage);
  }

  /**
 *
 *
 *
 *
 */
  isStorageAvailable(): boolean {
    return !!checkStorage(this.storage);
  }

  /**
 *
 *
 *
 */
  key(index: number): string | null {
    return this.storage ? this.storage.key(index) : null;
  }

  /**
 *
 *
 *
 *
 *
 *
 */
  setItem(key: string, value: string): boolean {
    if (!this.storage) {
      return false;
    }

    try {
      this.storage.setItem(key, value);
      return !!this.storage.getItem(key);
    } catch (err) {
      sendCustomError("localStorageError", {
        message: `${err} | ${window.location.href} | ${navigator.userAgent}`,
      });
      return false;
    }
  }

  /**
 *
 *
 *
 *
 */
  getItem(key: string): string | null {
    return this.storage ? this.storage.getItem(key) : null;
  }

  /**
 *
 *
 *
 *
 */
  removeItem(key: string): boolean {
    if (this.storage && !!key) {
      this.storage.removeItem(key);
      return !this.storage.getItem(key);
    }

    return false;
  }

  /**
 *
 *
 *
 */
  clear(): boolean {
    if (this.storage && this.storage.length !== undefined) {
      /*                                             */
      Object.keys(this.storage).forEach((k) => this.removeItem(k));
      /*                                             */
      return this.storage.length < 1;
    }

    return false;
  }

  /**
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 */
  setJson<T extends object>(key: string, data: T): boolean {
    try {
      return this.setItem(key, JSON.stringify(data));
    } catch {
      return false;
    }
  }

  /**
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 */
  getJson<T extends object>(key: string): T | null {
    try {
      const item = this.getItem(key);
      return item ? (JSON.parse(item) as T) : null;
    } catch {
      return null;
    }
  }
}

/**
 *
 *
 *
 *
 *
 *
 *
 */
export function globalStorage(
  storageObjectOrFunction: Storage | StorageWrapperFunc,
): GlobalStorage {
  return new GlobalStorage(storageObjectOrFunction);
}
